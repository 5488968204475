
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "company",
  setup(props, content) {
    const store = useStore();
    const route = useRoute();
    let exerciseList = ref([]);
    let productlistContentRef = ref(null);
    let styleLeft = ref("");
    let list = [];
    let intelScoll;

    function changeToItem() {
      if (route.path != "/about/company") return;
      let left = styleLeft.value - 1;
      const halfW = (240 * exerciseList.value.length) / 2;
      if (left <= -halfW) {
        left = 0;
      }
      styleLeft.value = left;
    }

    function stopScoll() {
      clearInterval(intelScoll);
    }

    function startScoll() {
      if (store.state.clientWidth <= 600 || list.length >= 4) {
        intelScoll = setInterval(() => {
          changeToItem();
        }, 30);
      }
    }

    async function getExcelData() {
      list = store.state.product;
      if (store.state.clientWidth <= 600 || list.length >= 4) {
        exerciseList.value = [...list, ...list];
        startScoll();
      } else {
        exerciseList.value = [...list];
      }
    }

    getExcelData();
    return {
      exerciseList,
      styleLeft,
      productlistContentRef,
      stopScoll,
      startScoll,
    };
  },
});
