<template>
  <div class="companyPageRoot">
    <div class="introduce">
      <div class="title">河南省豫建建筑工程技术服务中心</div>
      <div class="desc">Yu Jian Zhong Xin</div>
      <div class="content">河南省豫建建筑工程技术服务中心，是经河南省民政厅审核批准的以技术交流、研发、培训为主的非盈利性机构。中心始终坚持工学结合、知行合一、德技兼备的理念，以专业、严谨的工匠精神，为行业服务。</div>
      <div class="content">中心一直秉承着“<span class="mainColorText">工学结合、知行合一、德技兼备</span>”的理念服务于社会，为社会发展做出应有贡献。作为河南省一家非营利性机构，为河南省建筑行业培养了大量的优秀人才，赢得了企业的信任，及政府、社会的广泛支持和赞誉。</div>
      <div class="content">与此同时，中心坚持以人为本，尊重人的感情，从而在团队中形成了一种团结友爱、相互信任的和睦气氛，强化了团体意识，使员工之间形成强大的凝聚力和向心力。相同的价值观念形成了相同的目标和理想，员工把团队看成是一个命运共同体，把本职工作看成是实现共同目标的重要组成部分，整个团队步调一致，形成统一的整体。</div>
      <div class="content">我们依靠创新和科技的力量不断开拓向前，始终以诚信为准则，狠抓质量，精益求精，力所能及的为行业提供服务。</div>
    </div>
    <div class="product">
      <div class="title">成果展示</div>
      <div class="desc">Achievement Display</div>
      <div class="productContent">
        <div class="productWindow" @mouseenter="stopScoll" @mouseleave="startScoll">
          <div class="productlistContent" :style="{left:styleLeft+'px'}" ref="productlistContentRef">
            <div class="productListItem" v-for="(item,index) in exerciseList" :key="index">
              <el-image class="bannerImg" fit="contain" :src="item.src" :preview-src-list="[item.src]">
                <template #error>
                  <div class="err-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
                <template #placeholder>
                  <div class="err-slot">
                    加载中...
                  </div>
                </template>
              </el-image>
              <div class="cover">
                <i class="el-icon-zoom-in"></i>
              </div>
              <div class="productTitle">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, onMounted, onBeforeRouteEnter } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "company",
  setup(props, content) {
    const store = useStore();
    const route = useRoute();
    let exerciseList = ref([]);
    let productlistContentRef = ref(null);
    let styleLeft = ref("");
    let list = [];
    let intelScoll;

    function changeToItem() {
      if (route.path != "/about/company") return;
      let left = styleLeft.value - 1;
      const halfW = (240 * exerciseList.value.length) / 2;
      if (left <= -halfW) {
        left = 0;
      }
      styleLeft.value = left;
    }

    function stopScoll() {
      clearInterval(intelScoll);
    }

    function startScoll() {
      if (store.state.clientWidth <= 600 || list.length >= 4) {
        intelScoll = setInterval(() => {
          changeToItem();
        }, 30);
      }
    }

    async function getExcelData() {
      list = store.state.product;
      if (store.state.clientWidth <= 600 || list.length >= 4) {
        exerciseList.value = [...list, ...list];
        startScoll();
      } else {
        exerciseList.value = [...list];
      }
    }

    getExcelData();
    return {
      exerciseList,
      styleLeft,
      productlistContentRef,
      stopScoll,
      startScoll,
    };
  },
});
</script>
<style lang="less" scoped>
@blankColor: #5a5a5a;
@itemProductWidth: 284px;
@itemProductHeight: 220px;
@itemProductPadding: 10px;
@imgHeight: 165px;
.mainColorText {
  color: @mainColor;
}
.companyPageRoot {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  .introduce {
    margin-bottom: 50px;
    .title {
      color: @blankColor;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 24px;
    }
    .desc {
      margin-bottom: 40px;
      color: @blankColor;
      font-weight: bold;
      font-size: 21px;
    }
    .content {
      text-align: left;
      text-indent: 2em;
      font-size: 23px;
      line-height: 50px;
      color: @blankColor;
      letter-spacing: 1px;
    }
  }
  .product {
    width: @mainWidth;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    color: white;
    background-size: cover;
    background-position: center right;
    height: 377px;
    overflow: hidden;
    margin-top: 20px;
    .title {
      color: @blankColor;
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 24px;
    }
    .desc {
      margin-bottom: 40px;
      color: @blankColor;
      font-weight: bold;
      font-size: 21px;
    }
    .productContent {
      padding: 0 30px;
      position: relative;
      .productWindow {
        height: @itemProductHeight;
        overflow: hidden;
        position: relative;
        .productlistContent {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          width: max-content;
          margin: 0 auto;
          .productListItem {
            width: @itemProductWidth;
            min-height: @itemProductHeight;
            height: @itemProductHeight;
            background: white;
            margin: 0 10px;
            border-radius: 3px;
            background: #fbfbfb;
            background-size: 100% 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            &:hover {
              .productTitle {
                background: @orangeColor;
                color: white;
              }
              .cover {
                background: #00000099;
                i {
                  display: inline;
                }
              }
            }
            .bannerImg {
              background: #bbbbbb21;
              color: @orangeColor;
              flex: 1;
              width: 100%;
              /deep/ .el-icon-picture-outline {
                font-size: 40px;
              }
              .err-slot {
                text-align: center;
                color: @orangeColor;
              }
            }
            .productTitle {
              color: black;
              font-size: 17px;
              height: 55px;
              line-height: 55px;
              background: white;
            }
            .cover {
              height: @imgHeight;
              line-height: @imgHeight;
              width: 100%;
              pointer-events: none;
              position: absolute;
              top: 0;
              left: 0;
              text-align: center;
              i {
                font-size: 45px;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .companyPageRoot {
    max-width: 100vw;
    padding: 10px;
  }
}
</style>